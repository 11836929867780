import Collection from './Collection.js'

/**
 * @see http://download.oracle.com/javase/6/docs/api/java/util/List.html
 */
export default class List extends Collection {
  /**
     * Returns the element at the specified position in this list.
     * @param {number} index
     * @return {Object}
     */
  get() { }
  /**
     * Replaces the element at the specified position in this list with the
     * specified element (optional operation).
     * @param {number} index
     * @param {Object} e
     * @return {Object}
     */
  set() { }
  /**
     * Returns true if this collection contains no elements.
     * @return {boolean}
     */
  isEmpty() { }
}
